import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import ProjectService from '../services/projectService';
import ProjectToolsStore from './projectToolsStore';
import Project from '../models/Project';
import PinSettings from '../models/PinSettings';
import Pin from '../models/Pin';
import Icon from '../models/Icon';
import store from '../store/index';
import ApiProjectInterface from '../interfaces/ApiProjectInterface';
import ApiPinSettingsInterface from '../interfaces/ApiPinSettingsInterface';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectStore',
  store,
})

class ProjectStore extends VuexModule {
  public project: Project | null = null;
  public loadingError: boolean = false;


  @Action
  public async init(hash: string): Promise<void> {
    this.clearStore();
    const obj: Project | null = await this.convertProject(await ProjectService.getProjectByHash(hash));
    if (obj instanceof Project) {
      this.setProject(obj);
    } else {
      this.changeLoadingError(true);
    }
  };

  @Mutation
  public changeLoadingError(val: boolean): void {
    this.loadingError = val;
  }

  @Action
  public async convertPinSettings(pinSettings: ApiPinSettingsInterface): Promise<PinSettings> {
    const {tooltip_color, tooltip_width, tooltip_height, tooltip_auto_width, icon, ...pinSettingsRest} = pinSettings
    const iconAfterConvert: Icon = await ProjectToolsStore.convertIcon(icon)
    return new PinSettings({tooltipColor: tooltip_color, tooltipWidth: tooltip_width,
      tooltipHeight: tooltip_height, tooltipAutoWidth: tooltip_auto_width, icon: iconAfterConvert, ...pinSettingsRest});
  };

  @Action
  private async convertProject(response: ApiProjectInterface): Promise<Project | null> {
    if (response && 'user_id' in response && typeof response.user_id === 'number' ) {
      const {user_id, pins, pin_settings_id, pin_settings, original_image, auto_width, ...projectToCreate} = response;
      const pinsAfterConvert: Pin[] = [];
      const convertedPinSettings: PinSettings = await this.convertPinSettings(pin_settings);
      await pins.forEach(async (pin) => {
        const pinAfterConvert: Pin | null =  await ProjectToolsStore.convertPin(pin);
        if (pinAfterConvert) {
          pinsAfterConvert.push(pinAfterConvert);
        }
      });
      return new Project({userId: response.user_id, pins: pinsAfterConvert, pinSettingsId: pin_settings_id,
        originalImage: original_image, pinSettings: convertedPinSettings, autoWidth: auto_width, ...projectToCreate});
    } else {
      return null;
    }
  };

  @Mutation
  public setProject(project: Project): void {
    this.project = project;
  };

  @Mutation
  private clearStore(): void {
    this.project = null;
    this.loadingError = false;
  };
}

export default getModule(ProjectStore);
