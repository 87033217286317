







import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Pin from '../../models/Pin';
import ProjectStore from '../../store/projectStore';
import ProjectToolsStore from '../../store/projectToolsStore';


@Component({
  components: {
  },
})

export default class EmbedPinComponent extends Vue {

  @Prop()
  private pin!: Pin;

  private pinIconContent: string = '';

  private get currentScale(): number {
    return ProjectToolsStore.currentScale;
  };

  private set currentScale(payload: number) {
    ProjectToolsStore.setCurrentScale(payload);
  };

  private get pinSettingsColor(): string | undefined  {
    return ProjectStore.project!.pinSettings.color;
  };

  private get pinSettingsSize(): number | undefined  {
    return ProjectStore.project!.pinSettings.size;
  };

  private get pinSettingsIconContent(): string {
    return ProjectStore.project!.pinSettings.icon!.content;
  }

  private get pinSettingsIconCssSelector(): Array<{selector: string, prop: string}> {
    return ProjectStore.project!.pinSettings.icon!.cssSelector;
  }

  private changeIconsPropsWatch() {
    if (this.pin.pinIndividualStyle) {
      this.pinIconContent = this.pin.icon.content;
    } else {
      this.pinIconContent = this.pinSettingsIconContent;
    }
    this.$nextTick(() => {
      this.changeIconsProps();
    });
  };

  // @Watch('currentScale')
  private changeIconsPropsIndividual(selector: {selector: string, prop: string}): void {
    if (this.$el instanceof HTMLElement) {
      this.$el.style.width = (this.pin.size * this.currentScale).toString() + 'px';
      this.$el.style.height = (this.pin.size * this.currentScale).toString() + 'px';
      if (selector.selector !== '' && selector.prop !== '') {
        const svgElement: HTMLElement | null = this.$el.querySelector(selector.selector);
        if (svgElement) {
          this.changeIconColor(svgElement, selector, this.pin.color);
        }
      }
    }
  };

  // @Watch('currentScale')
  private changeIconsPropsPinSettings(selector: {selector: string, prop: string}): void {
    if (this.$el instanceof HTMLElement) {
      this.$el.style.width = (this.pinSettingsSize! * this.currentScale).toString() + 'px';
      this.$el.style.height = (this.pinSettingsSize! * this.currentScale).toString() + 'px';
      if (selector.selector !== '' && selector.prop !== '') {
        const svgElement: HTMLElement | null = this.$el.querySelector(selector.selector);
        if (svgElement) {
          this.changeIconColor(svgElement, selector, this.pinSettingsColor!);
        }
      }
    }
  };

  private changeIconColor(svgElement: HTMLElement, selector: {selector: string, prop: string}, color: string) {
    if (selector.prop === 'fill') {
      svgElement.style.fill = color;
    } else if (selector.prop === 'stroke') {
      svgElement.style.stroke = color;
    }
  };

  @Watch('currentScale')
  private changeIconsProps() {
    if (this.$el instanceof HTMLElement) {
      if ((typeof this.pin.iconDiffX) === 'number' && (typeof this.pin.iconDiffY) === 'number' &&
        !Number.isNaN(this.pin.iconDiffX) && !Number.isNaN(this.pin.iconDiffY)) {
        this.$el.style.left = (this.pin.iconDiffX * this.currentScale).toString() + 'px';
        this.$el.style.top = (this.pin.iconDiffY * this.currentScale).toString() + 'px';
      } else if (this.$el.parentElement instanceof HTMLElement) {
        this.$el.parentElement.style.display = 'flex';
        this.$el.parentElement.style.justifyContent = 'center';
        this.$el.parentElement.style.alignItems = 'center';
      }
    }
    if (this.pin.pinIndividualStyle) {
      this.pin.icon.cssSelector.forEach((selector) => {
        this.changeIconsPropsIndividual(selector);
      });
    } else if (!this.pin.pinIndividualStyle && this.pinSettingsColor && this.pinSettingsSize) {
      this.pinSettingsIconCssSelector.forEach((selector) => {
        this.changeIconsPropsPinSettings(selector);
      });
    }
  };
  private mounted() {
    this.changeIconsPropsWatch();
  };

}


