


































import {Component, Vue, Watch} from 'vue-property-decorator';
import ProjectToolsStore from '../../store/projectToolsStore';
import ProjectStore from '../../store/projectStore';
import Pin from '../../models/Pin';
import Project from '../../models/Project';
import EmbedTooltip from './EmbedTooltip.vue';
import EmbedPinComponent from './EmbedPinComponent.vue';

@Component({
  components: {
    EmbedPinComponent,
    EmbedTooltip,
  },
})

export default class Embed extends Vue {

  private imageSrc: string = '';
  public projectWidth: string = 'max-width: 1200px';
  private pins: Pin[] = [];
  private imgLoadCounter: number = 0;
  private activeIndex: number = 0;

  private get currentScale(): number {
    return ProjectToolsStore.currentScale;
  };

  private set currentScale(payload: number) {
    ProjectToolsStore.setCurrentScale(payload);
  };

  private get project(): Project | null {
    return ProjectStore.project;
  };

  private created(): void {
    if (this.$route.params.hash) {
      document.title = this.$route.params.hash;
    }
  };

  private mounted(): void {
    ProjectStore.init(this.$route.params.hash.toString());
    window.addEventListener('resize', this.resizeCurrentScale);
  };

  @Watch('project')
  private setProjectParams() {
    if (this.project) {

      let urlApi: string | undefined;
      if (window.location.hostname.includes('test')) {
        urlApi = process.env.VUE_APP_API_TEST_URL!;
      } else {
        urlApi = process.env.VUE_APP_API_URL!;
      }
      const timestamp: string = (new Date()).getTime().toString();
      this.imageSrc = urlApi + 'projects/image/' + this.project!.image + '?_=' + timestamp;
      if (this.project.pins) {
        this.pins = this.project.pins;
      }
      if (this.project.autoWidth) {
        this.projectWidth = 'max-width: 100%';
      } else {
        this.projectWidth = 'max-width: ' + (this.project?.width) + 'px';
      }
    }
  };

  private setStagePositionEventHandler(event: Event): void {
    const image: HTMLElement | null = document.querySelector('.zoom');
    const stage: HTMLElement | null = document.getElementById('main-shirk');
    // this.addResizeEventListener(image!);
    this.setStagePosition(event, stage!);
  };

  private resizeCurrentScale() {
    const image: HTMLElement | null = document.querySelector('.zoom');
    this.currentScale = ((image as HTMLImageElement).width / (image as HTMLImageElement).naturalWidth)
  };

  private addResizeEventListener(image: HTMLElement): void {
    const resizer = new ResizeObserver(() => this.currentScale =
      ((image as HTMLImageElement).width / (image as HTMLImageElement).naturalWidth)).observe(image);
  };

  private setStagePosition(event: Event, stage: HTMLElement) {
    const container: HTMLElement | null = document.getElementById('main-shirk');
    const image: HTMLImageElement = event.composedPath()[0] as HTMLImageElement;
    const fileExt: string = this.checkImageExtension();
    if (container) {
      if (fileExt === 'svg' && (image.height === 0 || image.width === 0)) {
        stage.style.width = image.naturalWidth + 'px';
        stage.style.height = image.naturalHeight + 'px';
      }
      this.currentScale =  image.width / image.naturalWidth;
      this.imgLoadCounter += 1;
    }
  };

  private checkImageExtension(): string {
    const splitedImgSrc: string[] = this.project!.image.split('.');
    return splitedImgSrc[splitedImgSrc.length - 1];
  };

  private tapHandlerend() {
    if (this.activeIndex > 0) {
      this.activeIndex = 0;
    }
  };

  private tapHandler(index: number) {
    if (this.activeIndex !== index) {
      this.activeIndex = index;
    }
  };
};
