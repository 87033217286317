import httpService from '../services/http';
import ApiProjectInterface from '../interfaces/ApiProjectInterface';

export default class ProjectService {

  public static async getProjectByHash(projectHash: string): Promise<ApiProjectInterface> {
    return httpService.get('projects/hash/' + projectHash)
      .then((response) => {
        return response.data})
      .catch((error) => {
        if (error.response.status === 403) {
          return {statusCode: error.response.status, data: error.response.data}
        } else {
          return null;
        }
      });
  };
};
