import Icon from '../models/Icon';

export default class PinSettings {
  public id: number = NaN;
  public width: number = NaN;
  public height: number = NaN;
  public size: number = NaN;
  public icon: Icon = {id: NaN, content: '', cssSelector: []}
  public color: string = '';
  public type: number = NaN;
  public tooltipColor: string = '';
  public tooltipWidth: number = NaN;
  public tooltipHeight: number = NaN;
  public tooltipAutoWidth: boolean = true;

  constructor(args: Partial<PinSettings>) {
    Object.assign(this, args)
  }
}

