import Icon from '../models/Icon';

export default class Pin {
  public id: number = NaN;
  public projectId: number = NaN;
  public type: number = NaN;
  public x: number = NaN;
  public y: number = NaN;
  public width: number = NaN;
  public height: number = NaN;
  public size: number = NaN;
  public color: string = '';
  public tooltipColor: string = '';
  public tooltipWidth: number = NaN;
  public tooltipHeight: number = NaN;
  public tooltipAutoWidth: boolean = true;
  public content: string = '';
  public redirectLink: string = '';
  public clickable: boolean = false;
  public tooltipIndividualStyle: boolean = false;
  public pinIndividualStyle: boolean = false;
  public icon: Icon = {id: NaN, content: '', cssSelector: []}
  public iconDiffX: number = NaN;
  public iconDiffY: number = NaN;

  constructor(args: Partial<Pin>) {
    Object.assign(this, args)
  }
}

