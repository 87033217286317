







import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Pin from '../../models/Pin';
import ProjectStore from '../../store/projectStore';
import ProjectToolsStore from '../../store/projectToolsStore';
import PinSettings from '../../models/PinSettings';
import vueCustomScrollbar from 'vue-custom-scrollbar';


@Component({
  components: {
    vueCustomScrollbar,
  },
})

export default class EmbedTooltip extends Vue {

  @Prop()
  private pin!: Pin;

  @Prop()
  private imgLoadCounter!: number;

  private customScrollbarsSettings = {
    suppressScrollY: false,
    suppressScrollX: true,
    wheelPropagation: false,
  };
  private key: number = Math.floor(Math.random() * (100000000 - 1) + 1);

  private get currentScale(): number {
    return ProjectToolsStore.currentScale;
  };

  private set currentScale(payload: number) {
    ProjectToolsStore.setCurrentScale(payload);
  };

  private get pinSettings(): Partial<PinSettings> | undefined {
    return ProjectStore.project?.pinSettings;
  }

  private updated() {
    this.setTooltipStyle();
    this.setTooltipPositionAndSize();
  }

  private setTooltipStyle(): void {
    if (this.$el instanceof HTMLElement) {
      if (this.pin.tooltipIndividualStyle) {
        this.$el.style.backgroundColor = this.pin.tooltipColor;
        this.$el.style.borderColor = this.pin.tooltipColor;
        if (!this.pin.tooltipAutoWidth) {
          this.$el.style.width = this.pin.tooltipWidth.toString() + 'px';
        }
      } else {
        this.$el.style.backgroundColor = this.pinSettings?.tooltipColor!;
        this.$el.style.borderColor = this.pinSettings?.tooltipColor!;
        if (!this.pinSettings?.tooltipAutoWidth) {
          this.$el.style.width = this.pinSettings?.tooltipWidth!.toString() + 'px';
        }
      }
    }
  };

  private setTooltipPositionAndSize(): void {
    const log = false;  // for tests

    if (this.$el instanceof HTMLElement && this.$el.parentElement! instanceof HTMLElement) {
      const container: HTMLElement | null = document.getElementById('main-shirk');

      const rawContWidth: number = (container!.offsetWidth);
      const rawContHeight: number = (container!.offsetHeight);
      const midX: number = rawContWidth / 2;
      const midY: number = rawContHeight / 2;
      let displayRight: boolean = true;
      let displayDown: boolean = true;
      let pinSize: number = this.pin.size;

      if (!this.pin.pinIndividualStyle && this.pinSettings && this.pinSettings.size) {
        pinSize = this.pinSettings.size;
      }

      if (this.pin.tooltipIndividualStyle && !this.pin.tooltipAutoWidth) {
        this.$el.style.width = this.pin.tooltipWidth.toString() + 'px';
        this.$el.style.overflowWrap = 'break-word';
        this.$el.style.whiteSpace = 'break-spaces';
        if (log) {console.log('tooltip width set individual'); }

      } else if (!this.pin.tooltipIndividualStyle && !this.pinSettings?.tooltipAutoWidth) {
        this.$el.style.width = this.pinSettings?.tooltipWidth!.toString() + 'px';
        this.$el.style.overflowWrap = 'break-word';
        this.$el.style.whiteSpace = 'break-spaces';
        if (log) {console.log('tooltip width set global'); }
      }

      if ((this.pin.x + this.pin.iconDiffX + pinSize / 2) * (this.currentScale) > midX) {
        this.$el.style.right = ((this.pin.width - this.pin.iconDiffX - pinSize / 2)
          * this.currentScale).toString() + 'px';
        this.$el.style.left = 'auto';
        displayRight = false;
        if (log) {
          console.log('display left');
          this.$el.style.backgroundColor = 'black';
        }
      } else {
        this.$el.style.left = ((this.pin.iconDiffX + pinSize / 2) * (this.currentScale)).toString() + 'px';
        this.$el.style.right = 'auto';
        if (log) {
          console.log('display right');
          this.$el.style.backgroundColor = 'brown';
        }
      }

      const totalWidth: number = (this.pin.x + this.pin.iconDiffX + pinSize / 2) *
        this.currentScale + this.$el.offsetWidth;
      if (this.$el.offsetWidth > rawContWidth * 0.8) {
        this.$el.style.width = (container!.offsetWidth * 0.8).toString() + 'px';
        this.$el.style.whiteSpace = 'normal';
        this.$el.style.left =
          (container!.offsetWidth * 0.1 - this.pin.x * this.currentScale).toString() + 'px';
        this.$el.style.right = '0px';
        if (log) {
          console.log('set width full');
          this.$el.style.backgroundColor = 'green';
        }
      } else if (displayRight && rawContWidth * 0.9 - totalWidth < 0) {
        this.$el.style.left = (rawContWidth * 0.9 - totalWidth +
          (this.pin.iconDiffX + pinSize / 2) * this.currentScale).toString() + 'px';
        if (log) {
          console.log('move left');
          this.$el.style.backgroundColor = 'yellow';
        }
      } else if (!displayRight && this.$el.offsetWidth >
        (this.pin.x + this.pin.iconDiffX + pinSize / 2) * this.currentScale * 0.9) {
        this.$el.style.right =
          (((this.pin.x + this.pin.width) * this.currentScale * 0.9) - this.$el.offsetWidth).toString() + 'px';
        if (log) {
          console.log('move right');
          this.$el.style.backgroundColor = 'blue';
        }
      }

      if (((this.pin.y + this.pin.iconDiffY + pinSize / 2) * this.currentScale) > midY) {
        this.$el.style.bottom = ((this.pin.height - this.pin.iconDiffY - pinSize / 2)
          * this.currentScale).toString() + 'px';
        displayDown = false;
        if (log) {console.log('display up'); }

      } else {
        this.$el.style.top = ((this.pin.iconDiffY + (pinSize / 2)) * this.currentScale).toString() + 'px';
        this.$el.style.bottom = 'auto';
        if (log) {console.log('display down'); }
      }

      const totalHeight: number = (this.pin.y + this.pin.iconDiffY + pinSize / 2) * this.currentScale
        + this.$el.offsetHeight;
      if (this.$el.offsetHeight > rawContHeight * 0.8) {
        if (log) {
          console.log('display full height');
          this.$el.style.backgroundImage = `linear-gradient(${this.$el.style.backgroundColor}, red)`;
        }
        this.$el.style.height = (container!.offsetHeight * 0.8).toString() + 'px';
        this.$el.style.top = (container!.offsetHeight * 0.1 - this.pin.y * this.currentScale ).toString() + 'px';
        this.$el.style.bottom = (container!.offsetHeight * 0.9 - this.pin.y * this.currentScale).toString() + 'px';

      } else if (displayDown && rawContHeight * 0.9 < totalHeight) {
        this.$el.style.top =
          ((this.pin.iconDiffY + pinSize / 2 + rawContHeight * 0.9 - totalHeight) *
            this.currentScale).toString() + 'px';
        if (log) {
          console.log('move up');
          this.$el.style.backgroundImage = `linear-gradient(${this.$el.style.backgroundColor}, pink)`;
        }
      } else if (!displayDown && this.$el.offsetHeight > (this.pin.y + this.pin.iconDiffY + pinSize / 2) *
        this.currentScale * 0.9) {
        this.$el.style.bottom =
          (-1 * (this.$el.offsetHeight - (this.pin.y + this.pin.height) * this.currentScale)).toString() + 'px';
        if (log) {
          console.log('move down');
          this.$el.style.backgroundImage = `linear-gradient(${this.$el.style.backgroundColor}, orange)`;
        }
      }

      if (this.$el.children[0] instanceof HTMLElement) {
        this.$el.children[0].style.height = (this.$el.offsetHeight - 16).toString() + 'px';
        this.$el.children[0].style.width = (this.$el.offsetWidth - 6).toString() + 'px';
        if (log) {console.log('m'); }
      }
    }
  };

  @Watch('currentScale')
  @Watch('imgLoadCounter')
  private catchCurrentScale(): void {
    this.key += 1;
  };
};

