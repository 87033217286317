import Permission from '../models/Permission';
import Pin from '../models/Pin';
import PinSettings from '../models/PinSettings';
export default class Project {
    public id: number = NaN;
    public title: string = '';
    public hash: string = '';
    public userId: number = NaN;
    public image: string = '';
    public originalImage: string = '';
    public thumbnail: string = '';
    public permission: Permission[] = [];
    public pins: Pin[] = [];
    public description: string = '';
    public public: boolean = false;
    public width: number = NaN;
    public autoWidth: boolean = true;
    public height: number = NaN;
    public pinSettingsId: number = NaN;
    public pinSettings: Partial<PinSettings> = {}

    constructor(args: Partial<Project>) {
      Object.assign(this, args)
    }
}

