import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '../store/index';
import Pin from '../models/Pin';
import Icon from '../models/Icon';
import ApiPinInterface from '../interfaces/ApiPinInterface';
import ApiIconInterface from '../interfaces/ApiIconInterface';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectToolsStore',
  store,
})

class ProjectToolsStore extends VuexModule {
  public currentScale: number = 1;

  @Mutation
  public setCurrentScale(payload: number): void {
    this.currentScale = payload;
  };

  @Action
  public async convertPin(response: ApiPinInterface | null): Promise<Pin | null> {
    if (response && 'project_id' in response && typeof response.project_id === 'number' ) {
      const {project_id, tooltip_color, icon, tooltip_width, tooltip_height, tooltip_auto_width, redirect_link,
        tooltip_individual_style, pin_individual_style, icon_diff_x, icon_diff_y, ...projectToCreate} = response;
      const iconAfterConvert: Icon = await this.convertIcon(icon);
      return new Pin({projectId: project_id, redirectLink: redirect_link, tooltipColor: tooltip_color,
        tooltipWidth: tooltip_width, tooltipHeight: tooltip_height, tooltipAutoWidth: tooltip_auto_width,
        tooltipIndividualStyle: tooltip_individual_style, pinIndividualStyle: pin_individual_style,
        icon: iconAfterConvert, iconDiffX: icon_diff_x, iconDiffY: icon_diff_y, ...projectToCreate});
    } else {
      return null;
    }
  };

  @Action
  public async convertIcon(payload: ApiIconInterface): Promise<Icon> {
    const {css_selector, ...restOfIcon} = payload;
    const cssSelectorArr: Array<{selector: string, prop: string}> = JSON.parse(css_selector);
    return new Icon({cssSelector: cssSelectorArr, ...restOfIcon});
  };
}

export default getModule(ProjectToolsStore);
