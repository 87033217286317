import Vue from 'vue';
import VueRouter from 'vue-router';
import Embed from '@/components/project/Embed.vue';
import NotFound from '@/components/project/NotFound.vue';

Vue.use(VueRouter);

const routes: any = [

    {
        // project embed
        name: 'embed',
        path: '/:hash/:mwidht?/:mheight?/:dwidth?/:dheight?',
        component: Embed,
    },
    {
        path: '*',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
