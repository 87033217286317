export default class Icon {
  public id: number = NaN;
  public content: string = '';
  public cssSelector: Array<{selector: string, prop: string}> = [];

  constructor(args: Partial<Icon>) {
    Object.assign(this, args)
  }
}

